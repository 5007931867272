import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Image from "next/image";
import Layout from "../components/layout";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Rating from "@material-ui/lab/Rating";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Helmet } from "react-helmet";
import Link from "next/link";
import Head from "next/head";
import SearchIcon from "@material-ui/icons/Search";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Router from "next/router";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
const Bangalore_Img = "/images/bangalore.webp";
const Hyderabad_Img = "/images/hyderabad.webp";
const Mumbai_Img = "/images/mumbai.webp";
const Pune_Img = "/images/pune.webp";
const homepage_coliving = "/images/homepage_coliving.jpg";
const homepage_roommate = "/images/homepage_roommate.jpg";
const homepage_entireHouse = "/images/homepage_entireHouse.jpg";
const homepage_pg = "/images/homepage_pg.jpg";
const homepage_room = "/images/homepage_room.jpg";
const homepage_flatmate = "/images/homepage_flatmate.jpg";
const how_it_works = "/images/how_it_works.svg";
const couple_couch = "/images/couple-couch.png";
const community = "/images/community.webp";

const styles = (theme) => ({
  heroSection: {
    width: '100%',
    height: '80vh',
    position: 'relative',
    overflow: 'hidden',
  },
  desktopImage: {
    display: 'block', // Display by default
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  heroSection1: {
    width: "100%",
    minHeight: "60vh",
    background: `url(${community})`,
    backgroundSize: "cover",
    [theme.breakpoints.down(800)]: {
      background: `url(${community})`,
      backgroundSize: "cover",
    },
    position: "relative",
  },
  section2: {
    height: "100%",
    margin: "64px auto",
    maxWidth: "1284px",
    padding: "0px 5%",
  },
  heroHeading2: {
    margin: "auto",
    maxWidth: "1284px",
    padding: "0px 5%",
  },
  heroHeading: {
    color: "white",
    width: "100%",
    position: "absolute",
    bottom: 0,
    padding: "2%",
    fontSize: "34px",
    [theme.breakpoints.down(500)]: {
      fontSize: "4vh",
    },
    fontWeight: "bold",
    lineHeight: 1.25,
    margin: "0px 0px 8px 0px",
    paddingLeft: "2%",
  },
  heroHeading4: {
    margin: "auto",
    maxWidth: "1284px",
    padding: "0px 5%",
  },
  heroHeading8: {
    color: "white",
    width: "100%",
    padding: "4% 2% 2% 2%",
    fontSize: "34px",
    [theme.breakpoints.down(500)]: {
      fontSize: "4vh",
    },
    fontWeight: "bold",
    lineHeight: 1.25,
    margin: "20px 0px 0px 0px",
    paddingLeft: "2%",
  },
  section4: {
    width: "100%",
    height: "100%",
    margin: "64px auto",
    textAlign: "center",
    background: "rgb(243, 241, 233)",
  },
  section5: {
    height: "100%",
    margin: "64px auto",
    maxWidth: "1284px",
    padding: "2% 5%",
  },
  section6: {
    position: "relative",
    height: "100%",
    margin: "auto",
    maxWidth: "1284px",
    padding: "0px 5% 50px 5%",
  },
  sectionHeading1: {
    fontSize: "34px",
    fontWeight: "bold",
    lineHeight: 1.25,
    color: theme.palette.primary.main,
    margin: "0px 0px 8px 0px",
    paddingLeft: "2%",
  },
  sectionHeading3: {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: 1.25,
    color: theme.palette.primary.main,
    margin: "0px 0px 8px 0px",
    paddingLeft: "2%",
  },
  sectionHeading2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: theme.palette.ternary.main,
    margin: "0px 0px 32px 0px",
    paddingLeft: "2%",
  },
  cityContainer: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    flexWrap: "inherit !important",
  },
  roomTypeContainerOuter: {
    margin: "64px 0 64px -12px",
    display: "flex",
    "& > .MuiGrid-root": {
      position: "relative",
      "& > .roomTypeHeadings": {
        width: "100%",
        paddingLeft: "2%",
        [theme.breakpoints.up("md")]: {
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        },
      },
    },
  },
  roomTypeContainer: {
    display: "flex",
  },
  cityPaper: {
    position: "relative",
    height: "390px",
    display: "block",
    cursor: "pointer",
    borderRadius: "8px",
    boxShadow: "0 8px 16px 0 rgba(51, 51, 51, .08)",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    width: "100%",
  },
  roomTypePaper: {
    position: "relative",
    height: "190px",
    display: "block",
    cursor: "pointer",
    borderRadius: "8px",
    boxShadow: "0 8px 16px 0 rgba(51, 51, 51, .08)",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    width: "100%",
  },
  testimonialPaper: {
    position: "relative",
    display: "block",
    cursor: "pointer",
    borderRadius: "8px",
    boxShadow: "0 8px 16px 0 rgba(51, 51, 51, .08)",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    width: "100%",
    marginBottom: "10px",
  },
  cityName: {
    bottom: "0px !important",
    position: "absolute",
    margin: "0px",
    padding: "20px",
    color: "white",
    fontSize: "18px",
  },
  cityImage: {
    height: "70%",
    position: "relative",
    backgroundSize: "cover !important",
  },
  roomTypeImage: {
    height: "100%",
    position: "relative",
    backgroundSize: "cover !important",
  },
  bangalore: {
    background: `url(${Bangalore_Img})`,
  },
  hyderabad: {
    background: `url(${Hyderabad_Img})`,
  },
  mumbai: {
    background: `url(${Mumbai_Img})`,
  },
  pune: {
    background: `url(${Pune_Img})`,
  },
  homepage_coliving: {
    background: `url(${homepage_coliving})`,
  },
  homepage_roommate: {
    background: `url(${homepage_roommate})`,
  },
  homepage_entireHouse: {
    background: `url(${homepage_entireHouse})`,
  },
  homepage_pg: {
    background: `url(${homepage_pg})`,
  },
  homepage_room: {
    background: `url(${homepage_room})`,
  },
  homepage_flatmate: {
    background: `url(${homepage_flatmate})`,
  },
  cityInfo: {
    padding: "20px",
  },
  testimonialInfo: {
    padding: "20px",
    width: "236px",
  },
  cityInfo2: {
    whiteSpace: "initial !important",
    lineHeight: "1.4",
  },
  testimonialInfo2: {
    whiteSpace: "initial !important",
    lineHeight: "1.4",
    margin: "10px 0 !important",
  },
  howItWorksContainer: {
    width: "100%",
    height: "100%",
    margin: "auto",
    maxWidth: "1284px",
    "& > div": {
      padding: "12px 0px !important",
    },
    padding: "0 5%",
  },
  sectionHeading4: {
    width: "100%",
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: "34px",
    fontWeight: "bold",
    lineHeight: 1.25,
    paddingLeft: "2%",
  },
  sectionHeading6: {
    width: "100%",
    textAlign: "left",
    color: theme.palette.primary.main,
    fontSize: "34px",
    fontWeight: "bold",
    lineHeight: 1.25,
    paddingLeft: "4%",
  },
  sectionHeadingp: {
    paddingLeft: "2%",
  },
  checkIcon: {
    color: "rgb(4, 175, 136)",
    marginRight: "10px",
  },
  points: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    margin: "1.5rem",
  },
  howItWorksImage: {
    position: 'relative',
    display: "flex",
    alignItems: "center",
    "& div": {
      position: 'inherit !important',
      width: '100%',
      height: '80%',
    },
    "& img": {
      position: 'inherit !important',
      [theme.breakpoints.up("md")]: {
        marginLeft: "auto",
      },
      [theme.breakpoints.down("md")]: {
        margin: "auto",
        width: '100%',
        height: '200px !important'
      },
    },
  },
  free100: {
    minHeight: '250px',
    position: 'relative',
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "& div": {
      position: 'inherit !important',
      width: '100%',
    },
    "& img": {
      position: 'inherit !important',
      position: 'inherit !important',
      [theme.breakpoints.up("sm")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
        width: '100%',
        height: '200px !important'
      },
    },
    "& div": {
      "& p": {
        opacity: 0.6,
        padding: "0px 5%",
        fontSize: "1rem",
        lineHeight: 1.5,
      },
    },
  },
  free101: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    padding: "0 4% !important",
    color: "white",
    fontSize: "1rem",
  },
  citiesDivider: {
    display: "block",
    color: "#1E3255",
    margin: "96px auto",
    padding: "0px 5%",
    maxWidth: "1284px",
  },
  sectionHeading5: {
    fontSize: "34px",
    fontWeight: "bold",
    lineHeight: 1.25,
    color: theme.palette.primary.main,
    padding: "0px 5%",
  },
  free102: {
    [theme.breakpoints.up(625)]: {
      marginTop: "60px",
    },
    [theme.breakpoints.down(625)]: {
      marginTop: "30px",
    },
  },
  buttonhowitworks: {
    textAlign: "left",
    alignItems: "left",
    margin: "1.6rem",
    "& a": {
      textDecoration: "none",
      color: "white",
    },
  },
  question: {
    fontSize: "25px",
    fontWeight: "bold",
    marginBottom: "30px",
    lineHeight: 1.5,
  },
  paperButton: {
    fontSize: "1rem",
    padding: "12px 24px",
    fontWeight: "900",
    color: "#fff",
  },
  card: {
    borderRadius: 12,
    minWidth: 256,
    textAlign: "center",
  },
  avatar: {
    width: 60,
    height: 60,
    margin: "auto",
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    marginTop: 8,
    marginBottom: 0,
    color: "white",
    textAlign: "center",
  },
  statLabel: {
    fontSize: 12,
    fontWeight: 500,
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    letterSpacing: "1px",
    margin: 0,
  },
  cardBlore: {
    background: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${Bangalore_Img})`,
    backgroundSize: "cover",
  },
  cardPune: {
    background: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${Pune_Img})`,
    backgroundSize: "cover",
  },
  cardHyd: {
    background: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${Hyderabad_Img})`,
    backgroundSize: "cover",
  },
  cardMumbai: {
    background: `linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${Mumbai_Img})`,
    backgroundSize: "cover",
  },
  rateValue: {
    fontWeight: "bold",
    marginLeft: "3px",
  },
  ratingBox: {
    margin: "0 0 8px 0",
    justifyContent: "center",
  },
  reviewText: {
    fontSize: "12px",
    margin: "0px 5px",
  },
  testimonialGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  search: {
    borderRadius: "10px !important",
    display: "flex",
    alignItems: "center",
    "& input": {
      width: "100% !important",
      height: "50px",
      borderRadius: "10px !important",
    },
    boxShadow: "0 10px 50px #1f3356, 0 0 0 8px #ffffffeb",
  },
  searchContainer: {
    position: "absolute",
    top: "20%",
    maxWidth: "500px",
    width: "90% !important",
    //width: 'fit-content',
    margin: "auto",
    left: 0,
    right: 0,
    color: "white",
    textAlign: "center",
  },
  searchIcon: {
    "& svg": {
      marginLeft: "-25px",
      color: "black !important",
    },
    display: "flex",
    cursor: "pointer",
  },
  testiScrollLeft: {
    position: "absolute",
    bottom: "14px",
    width: "32px",
    height: "32px",
    right: "36px",
    borderRadius: "50%",
    minWidth: "unset !important",
    "& svg": {
      color: "white",
    },
    [theme.breakpoints.down(750)]: {
      display: "none",
    },
  },
  testiScrollRight: {
    position: "absolute",
    bottom: "14px",
    right: 0,
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    minWidth: "unset !important",
    "& svg": {
      color: "white",
    },
    [theme.breakpoints.down(750)]: {
      display: "none",
    },
  },
  testiButtonsContainer: {
    marginLeft: "auto",
    position: "relative",
    marginBottom: "14px",
  },
  communityNumbers: {
    [theme.breakpoints.up(625)]: {
      fontSize: "4vw",
    },
    [theme.breakpoints.down(625)]: {
      fontSize: "6vw",
    },
    margin: 0,
  },
  communityContainer: {
    [theme.breakpoints.up(625)]: {
      margin: "auto",
    },
  },
  communityDivider: {
    backgroundColor: "white !important",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  communityButton: {
    color: "white !important",
    [theme.breakpoints.up(625)]: {
      margin: "50px 0 0 2%",
    },
    [theme.breakpoints.down(625)]: {
      margin: "50px 0 0 0",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} className="noMargin">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "rgb(4, 175, 136)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledRating = withStyles({
  iconFilled: {
    color: "rgb(4, 175, 136)",
  },
})(Rating);

class HomePage extends React.Component {
  state = { scrollTop: 0, tabsValue: 0, showTopBar: true };

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY < 120) {
      this.setState({ showTopBar: true });
    } else {
      this.setState({ showTopBar: false });
    }
  };

  handleTabs = (event, newValue) => {
    this.setState({ tabsValue: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ tabsValue: index });
  };

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  handleSearch = (result) => {
    Router.push(
      "/properties/" +
      result.structured_formatting.main_text
        .replaceAll(/, /g, "-")
        .replaceAll(/ /g, "-")
    );
  };

  testiScrollRight = () => {
    var container = document.getElementById("testiSlider");
    this.sideScroll(container, "right", 25, 200, 5);
  };

  testiScrollLeft = () => {
    var container = document.getElementById("testiSlider");
    this.sideScroll(container, "left", 25, 200, 5);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  myLoader = ({ src }) => {
    return `${src}`;
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout isHomePage showTopBar={this.state.showTopBar}>
        <div className={classes.homePage}>
          <Head>
            <title>
              {" "}
              {`Find Flatmate/Roommate, Room, Apartment, PG | Post Free Ad`}{" "}
            </title>
            <meta
              name="title"
              content={`Find Roommates, Flatmates, Apartment, PG, Coliving | Post Free Ad`}
            />
            <meta
              name="description"
              content={`Over 100,000 People Already Trust FindMyRoom. Join Us! Advertise your vacant space online for Free. Find Flatmates, Coliving Rooms, PG, or Apartments.`}
            />
            <link rel="canonical" href="https://findmyroom.in/" />
          </Head>
          <Helmet>
            <title>
              {" "}
              {`Find Flatmate/Roommate, Room, Apartment, PG | Post Free Ad`}{" "}
            </title>
            <meta
              name="description"
              content={`Over 100,000 People Already Trust FindMyRoom. Join Us! Advertise your vacant space online for Free. Find Flatmates, Coliving Rooms, PG, or Apartments.`}
            />
          </Helmet>
          <section className={classes.heroSection}>
            <img
              className={classes.desktopImage}
              src="/images/hero2.jpg"
              srcset="/images/hero3Mobile.webp 800w, /images/hero2.jpg 1600w"
              alt="FindMyRoom"
            />
            <div className={classes.searchContainer}>
              <p style={{ margin: "20px 0" }}>
                10000+ rooms and flatmates available now across India
              </p>
              <div className={classes.search}>
                <GooglePlacesAutocomplete
                  onSelect={this.handleSearch}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["in"],
                    },
                    types: ["(regions)"],
                  }}
                  displayPoweredByGoogle={true}
                  placeholder="Search by Locality"
                //ref={textInput}
                />
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
              </div>
            </div>
            <div className={classes.heroHeading}>
              <h1 className={classes.heroHeading2}>
                Find A Room / Roommate <br /> Now{" "}
              </h1>
            </div>
          </section>

          {/*Section2 */}

          <section className={classes.section2} id="cities">
            <h2 className={classes.sectionHeading1}>Our top cities</h2>
            <p className={classes.sectionHeading2}>
              Choose the city you’ll be living in next, or look for flatmates
              and rooms near you
            </p>

            <Grid
              container
              spacing={3}
              className={classNames(classes.cityContainer, "cityContainer")}
            >
              <Grid item xs={10} md={5} lg={3}>
                <a
                  href="/properties/bangalore"
                  style={{ textDecoration: "none" }}
                >
                  <Paper className={classNames(classes.cityPaper)}>
                    <div
                      className={classNames(
                        classes.cityImage,
                        classes.bangalore
                      )}
                    >
                      <h2 className={classes.cityName}>Bangalore</h2>
                    </div>
                    <div className={classes.cityInfo}>
                      <div className={classes.cityInfo1}>
                        {" "}
                        2500+ listings every month{" "}
                      </div>
                      <p className={classes.cityInfo2}>
                        {" "}
                        The center of India's high-tech industry{" "}
                      </p>
                    </div>
                  </Paper>
                </a>
              </Grid>
              <Grid item xs={10} md={5} lg={3}>
                <a href="/properties/pune" style={{ textDecoration: "none" }}>
                  <Paper className={classNames(classes.cityPaper)}>
                    <div
                      className={classNames(classes.cityImage, classes.pune)}
                    >
                      <h2 className={classes.cityName}>Pune</h2>
                    </div>
                    <div className={classes.cityInfo}>
                      <div className={classes.cityInfo1}>
                        {" "}
                        3000+ listings every month{" "}
                      </div>
                      <p className={classes.cityInfo2}>
                        {" "}
                        The eighth largest metropolises in India{" "}
                      </p>
                    </div>
                  </Paper>
                </a>
              </Grid>
              <Grid item xs={10} md={5} lg={3}>
                <a
                  href="/properties/hyderabad"
                  style={{ textDecoration: "none" }}
                >
                  <Paper className={classNames(classes.cityPaper)}>
                    <div
                      className={classNames(
                        classes.cityImage,
                        classes.hyderabad
                      )}
                    >
                      <h2 className={classes.cityName}>Hyderabad</h2>
                    </div>
                    <div className={classes.cityInfo}>
                      <div className={classes.cityInfo1}>
                        {" "}
                        1000+ listings every month{" "}
                      </div>
                      <p className={classes.cityInfo2}>
                        {" "}
                        A major center for the technology industry{" "}
                      </p>
                    </div>
                  </Paper>
                </a>
              </Grid>
              <Grid item xs={10} md={5} lg={3}>
                <a href="/properties/mumbai" style={{ textDecoration: "none" }}>
                  <Paper className={classNames(classes.cityPaper)}>
                    <div
                      className={classNames(classes.cityImage, classes.mumbai)}
                    >
                      <h2 className={classes.cityName}>Mumbai</h2>
                    </div>
                    <div className={classes.cityInfo}>
                      <div className={classes.cityInfo1}>
                        {" "}
                        1000+ listings every month{" "}
                      </div>
                      <p className={classes.cityInfo2}>
                        {" "}
                        A financial center and India's largest city{" "}
                      </p>
                    </div>
                  </Paper>
                </a>
              </Grid>
            </Grid>
          </section>
          <section className={classes.citiesDivider}>
            <hr />
          </section>
          <section className={classes.section2} id="cities">
            <Grid
              container
              spacing={3}
              className={classNames(
                classes.roomTypeContainerOuter,
                "roomTypeContainerOuter"
              )}
            >
              <Grid item xs={12} md={4}>
                <div className="roomTypeHeadings">
                  <h3 className={classes.sectionHeading3}>
                    Explore the latest
                  </h3>
                  <h2 className={classes.sectionHeading1}>FindMyRoom</h2>
                  <h3 className={classes.sectionHeading3}>listings</h3>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid
                  container
                  spacing={3}
                  className={classNames(
                    classes.roomTypeContainer,
                    "roomTypeContainer"
                  )}
                >
                  <Grid item xs={6} md={4}>
                    <a href="/roommate" style={{ textDecoration: "none" }}>
                      <Paper className={classNames(classes.roomTypePaper)}>
                        <div
                          className={classNames(
                            classes.roomTypeImage,
                            classes.homepage_roommate
                          )}
                        >
                          <h2 className={classes.cityName}>Roommate</h2>
                        </div>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <a href="/co-living" style={{ textDecoration: "none" }}>
                      <Paper className={classNames(classes.roomTypePaper)}>
                        <div
                          className={classNames(
                            classes.roomTypeImage,
                            classes.homepage_coliving
                          )}
                        >
                          <h2 className={classes.cityName}>Coliving</h2>
                        </div>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <a href="/pg" style={{ textDecoration: "none" }}>
                      <Paper className={classNames(classes.roomTypePaper)}>
                        <div
                          className={classNames(
                            classes.roomTypeImage,
                            classes.homepage_pg
                          )}
                        >
                          <h2 className={classes.cityName}>PG</h2>
                        </div>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <a href="/flatmate" style={{ textDecoration: "none" }}>
                      <Paper className={classNames(classes.roomTypePaper)}>
                        <div
                          className={classNames(
                            classes.roomTypeImage,
                            classes.homepage_flatmate
                          )}
                        >
                          <h2 className={classes.cityName}>Flatmate</h2>
                        </div>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <a href="/room" style={{ textDecoration: "none" }}>
                      <Paper className={classNames(classes.roomTypePaper)}>
                        <div
                          className={classNames(
                            classes.roomTypeImage,
                            classes.homepage_room
                          )}
                        >
                          <h2 className={classes.cityName}>Room</h2>
                        </div>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <a href="/entire-house" style={{ textDecoration: "none" }}>
                      <Paper className={classNames(classes.roomTypePaper)}>
                        <div
                          className={classNames(
                            classes.roomTypeImage,
                            classes.homepage_entireHouse
                          )}
                        >
                          <h2 className={classes.cityName}>Entire House</h2>
                        </div>
                      </Paper>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </section>

          <section className={classes.section4}>
            <Grid container spacing={3} className={classes.howItWorksContainer}>
              <Grid item xs={12} md={6}>
                <h3 className={classes.sectionHeading6}>How It Works</h3>
                <StyledTabs
                  value={this.state.tabsValue}
                  onChange={this.handleTabs}
                  indicatorColor="secondary"
                  textColor="primary"
                >
                  <Tab label="Rent A Room" {...this.a11yProps(0)} />
                  <Tab label="Find A Room" {...this.a11yProps(1)} />
                </StyledTabs>

                <SwipeableViews
                  containerStyle={{
                    transition:
                      "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
                  }}
                  index={this.state.tabsValue}
                  onChangeIndex={this.handleChangeIndex}
                >
                  <TabPanel value={this.state.tabsValue} index={0}>
                    <p className={classes.points}>
                      <CheckCircleIcon className={classes.checkIcon} />
                      Fill up a form with the basic details about your Apartment
                    </p>
                    <p className={classes.points}>
                      <CheckCircleIcon className={classes.checkIcon} />
                      Sign up, complete your profile and post your listing for
                      Free
                    </p>
                    <p className={classes.points}>
                      <CheckCircleIcon className={classes.checkIcon} />
                      That is it! Your listing is now in front of thousands of
                      Seekers
                    </p>
                    <div className={classes.buttonhowitworks}>
                      <Link href="/post/rent">
                        <Button variant="outlined" color="primary">
                          <strong>Get Started</strong>
                        </Button>
                      </Link>
                    </div>
                  </TabPanel>
                  <TabPanel value={this.state.tabsValue} index={1}>
                    <p className={classes.points}>
                      <CheckCircleIcon className={classes.checkIcon} />
                      Search for a locality and find the right post or a listing
                    </p>
                    <p className={classes.points}>
                      <CheckCircleIcon className={classes.checkIcon} />
                      Contact the roommate or a landlord to close the deal
                    </p>
                    <p className={classes.points}>
                      <CheckCircleIcon className={classes.checkIcon} />
                      That is it! Ready to move in?
                    </p>
                    <div className={classes.buttonhowitworks}>
                      <Link href="/properties/bangalore">
                        <Button variant="outlined" color="primary">
                          <strong>Get Started</strong>
                        </Button>
                      </Link>
                    </div>
                  </TabPanel>
                </SwipeableViews>
              </Grid>
              <Grid item xs={12} md={6} className={classes.howItWorksImage}>
                <Image
                  src={how_it_works}
                  layout="fill"
                  sizes="(min-width: 75em) 33vw,
                  (min-width: 48em) 50vw,
                  100vw"
                />
              </Grid>
            </Grid>
          </section>

          <section className={classes.section5}>
            <Box display={{ xs: "none", sm: "block" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className={classes.free100}>
                  <Image
                    src={couple_couch}
                    layout="fill"
                    sizes="(min-width: 75em) 33vw,
                    (min-width: 48em) 50vw,
                    100vw"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.free100}>
                  <div>
                    <h4 className={classes.sectionHeading5}>
                      Why use FindMyRoom?
                    </h4>
                    <p>
                      FindMyRoom is a one-stop destination for anyone looking out for roommates, flatmates, co-living spaces or PGs. We understand that finding the right living situation can be a challenge, which is why we make the process easy and stress-free.  Whether you're a student searching for a roommate or a young professional seeking a flatmate, our platform provides a streamlined and efficient way to connect with like-minded individuals in search of a shared living space.
                      <br></br><br></br>Our platform allows you to browse through a wide range of listings for co-living spaces, PGs, and flats, and find the perfect match for your lifestyle and preferences. So, why wait? Start your search today and find your ideal living situation with FindMyRoom!
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box display={{ xs: "block", sm: "none" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className={classes.free100}>
                  <div>
                    <h4 className={classes.sectionHeading5}>
                      Why use FindMyRoom?
                    </h4>
                    <p>
                      FindMyRoom helps people to find a flatmate, roommate, rent
                      a room or apartment in Top Cities of India. We make a
                      direct connection between roommates or landlords with the
                      seekers. <br></br>
                      <br></br>We want to make sure that you have complete
                      access to everything we have to offer without those
                      annoying upgrades. We know your experience will be so
                      awesome that you will share us and recommend us to
                      everyone you know as we grow. We believe that once you use
                      findmyroom.in you’ll agree that it’s the best roommate
                      finding experience there is.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.free100}>
                  <Image
                    src={couple_couch}
                    layout="fill"
                    sizes="(min-width: 75em) 33vw,
                    (min-width: 48em) 50vw,
                    100vw"
                  />
                </Grid>
              </Grid>
            </Box>
          </section>

          <section className={classes.heroSection1}>
            <div className={classes.heroHeading4}>
              <h2 className={classes.heroHeading8}>
                Join our community Today and get notified{" "}
              </h2>
              <Grid className={classes.free102} container spacing={3}>
                <Grid item xs={6} sm={5} className={classes.free101}>
                  <div className={classes.communityContainer}>
                    <h3 className={classes.communityNumbers}>250,000+</h3>
                    <p>Followers</p>
                  </div>
                </Grid>
                <Divider
                  className={classes.communityDivider}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs={6} sm={4} className={classes.free101}>
                  <div className={classes.communityContainer}>
                    <h3 className={classes.communityNumbers}>80,000+</h3>
                    <p>Active Users</p>
                  </div>
                </Grid>
                <Divider
                  className={classes.communityDivider}
                  orientation="vertical"
                  flexItem
                />
                <Grid item xs={6} sm={2} className={classes.free101}>
                  <div className={classes.communityContainer}>
                    <h3 className={classes.communityNumbers}>10</h3>
                    <p>Cities</p>
                  </div>
                </Grid>
              </Grid>

              <a href="/flat-and-flatmates" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.communityButton}
                >
                  More Info
                </Button>
              </a>
            </div>
          </section>

          <section className={classes.section6}>
            <div style={{ display: "flex" }}>
              <div>
                <h4
                  className={classes.sectionHeading4}
                  style={{ marginBottom: 0 }}
                >
                  User Testimonials
                </h4>
                <p className={classes.sectionHeadingp}>
                  Our users really love us! Please review us or provide a
                  feedback here
                </p>
              </div>
              <div className={classes.testiButtonsContainer}>
                <Button
                  onClick={this.testiScrollLeft}
                  className={classes.testiScrollLeft}
                  variant="contained"
                  color="secondary"
                >
                  <KeyboardArrowLeftIcon variant="contained" id="slideLeft" />
                </Button>
                <Button
                  onClick={this.testiScrollRight}
                  className={classes.testiScrollRight}
                  variant="contained"
                  color="secondary"
                >
                  <KeyboardArrowRightIcon variant="contained" id="slideRight" />
                </Button>
              </div>
            </div>
            <Grid
              container
              spacing={3}
              className={classNames(
                classes.cityContainer,
                "cityContainer",
                classes.testiSlider
              )}
              style={{ marginTop: "25px" }}
              id="testiSlider"
            >
              <Grid
                item
                xs={10}
                md={5}
                lg={3}
                className={classes.testimonialGrid}
              >
                <div>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Sahanka Akini</b> reviewed <b>FindMyRoom</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={5} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        FindMyRoom is a quick and easy way to find a trustworthy
                        roommate compared to other platforms. Nice to see an
                        application that emphasizes security and provides
                        quality service{" "}
                      </p>
                    </div>
                  </Paper>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Aditi Shukla</b> reviewed <b>FindMyRoom</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={4} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        I got amazing flat and super cool flatmates{" "}
                      </p>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid
                item
                xs={10}
                md={5}
                lg={3}
                className={classes.testimonialGrid}
              >
                <div>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.cityInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Manish Sharma</b> reviewed{" "}
                        <b>Flat and Flatmates Bangalore</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={4} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        I was able to rent my apartment in whitefield, bangalore
                        with the help of the platform. Good work folks. Keep it
                        going{" "}
                      </p>
                    </div>
                  </Paper>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Rajamanickam Sekar</b> reviewed{" "}
                        <b> Flat and Flatmates Pune</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={5} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        Very good response. Easy to find roommates or tenants.{" "}
                      </p>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid
                item
                xs={10}
                md={5}
                lg={3}
                className={classes.testimonialGrid}
              >
                <div>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Akash</b> reviewed{" "}
                        <b>Flat and Flatmates Hyderabad</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={5} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        I was able to find a PG in HSR layout with the help of
                        FindMyRoom. Easy to find PG and Coliving Spaces in
                        Bangalore{" "}
                      </p>
                    </div>
                  </Paper>

                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Sumeet Sahu</b> reviewed{" "}
                        <b>Flat and Flatmates Delhi</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={5} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        Nice page and easy to find a flatmate{" "}
                      </p>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid
                item
                xs={10}
                md={5}
                lg={3}
                className={classes.testimonialGrid}
              >
                <div>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Rohit Yadav</b> reviewed{" "}
                        <b>Flat and Flatmates Bangalore</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={5} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        Awesome place to find Short-Term And Long-Terms Rentals
                        at a Reasonable Price. The best part is without any
                        brokage. The Team Will Do Their Best To Get A Good Deal
                        For You{" "}
                      </p>
                    </div>
                  </Paper>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Tanya joshi</b> reviewed{" "}
                        <b> Flat and Flatmates Pune</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={4} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        Good options for people who are looking for flats or
                        roommates{" "}
                      </p>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid
                item
                xs={10}
                md={5}
                lg={3}
                className={classes.testimonialGrid}
              >
                <div>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b> Pooja Deshmukh</b> reviewed{" "}
                        <b> Flat and Flatmates Mumbai</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={5} readOnly />
                      <p className={classes.testimonialInfo2}>
                        It's very helpful coummunity. It is easy to find
                        roommies on flat and flatmate page of this company{" "}
                      </p>
                    </div>
                  </Paper>
                  <Paper className={classNames(classes.testimonialPaper)}>
                    <div className={classes.testimonialInfo}>
                      <div
                        style={{ whiteSpace: "initial", marginBottom: "10px" }}
                      >
                        {" "}
                        <b>Vishakha Singh</b> reviewed{" "}
                        <b> Flat and Flatmates Ahmedabad</b>{" "}
                      </div>
                      <StyledRating name="read-only" value={4.4} readOnly />
                      <p className={classes.testimonialInfo2}>
                        {" "}
                        Better way to find flat and flatmates{" "}
                      </p>
                    </div>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </section>

          {/*                     <section className={classes.section2} id="cities">
                        <h2 className={classes.sectionHeading1}>Facebook Communities</h2>
                        <p className={classes.sectionHeading2}>Follow Us On Facebook For More Updates</p>

                        <Grid container spacing={3} className={classNames(classes.cityContainer, "cityContainer")}>

                            <Grid item xs={10} md={5} lg={3}>
                                <Card className={classNames(classes.cityPaper1)}>
                                    <CardContent className={classes.cardBlore}>
                                        <Avatar className={classes.avatar} src={bloredp} />
                                        <h2 className={classes.heading}>Flat and Flatmates<br></br>Bangalore</h2>
                                    </CardContent>
                                    <Divider light />
                                    <FacebookIcon style={{ color: '#4267B2', margin: '6px', }} fontSize="large" />
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        className={classes.ratingBox}
                                    >
                                        <Rating name={'rating'} value={5} size={'small'} readOnly />
                                        <Typography variant={'body2'} className={classes.rateValue}>
                                            5.0
                                </Typography>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        className={classes.ratingBox}
                                    >
                                        <a href="https://www.facebook.com/FindMyRoomBangalore/reviews/" target="_blank" style={{ textDecoration: "none", }}>
                                            <Typography className={classes.reviewText}>
                                                144 Reviews
                                </Typography></a> |
                                <a href="https://www.facebook.com/FindMyRoomBangalore/reviews/" target="_blank" style={{ textDecoration: "none" }}>
                                            <Typography className={classes.reviewText}>
                                                Write a review
                                </Typography></a>
                                    </Box>
                                    <Divider light variant="middle" style={{ maxWidth: '70%', margin: '12px auto' }} />
                                    <Box display={'flex'}>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <p className={classes.statLabel}>Likes</p>
                                            <p className={classes.statValue}>29,195</p>
                                        </Box>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <p className={classes.statLabel}>Followers</p>
                                            <p className={classes.statValue}>3.2k</p>
                                        </Box>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <Chip
                                                label="Like Page"
                                                clickable
                                                icon={<ThumbUpAltIcon style={{ color: 'white' }} />}
                                                color="secondary"
                                                onClick={() => window.open("https://www.facebook.com/FindMyRoomBangalore/", "_blank")}
                                                style={{ color: 'white' }}
                                            />
                                        </Box>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <Chip
                                                label="Join Group"
                                                clickable
                                                icon={<AddIcon style={{ color: 'white' }} />}
                                                onClick={() => window.open("https://www.facebook.com/groups/FindMyRoomBangalore/", "_blank")}
                                                color="secondary"
                                                style={{ color: 'white' }}
                                            />
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={10} md={5} lg={3}>
                                <Card className={classNames(classes.cityPaper1)}>
                                    <CardContent className={classes.cardPune}>
                                        <Avatar className={classes.avatar} src={punedp} />
                                        <h2 className={classes.heading}>Flat and Flatmates<br></br>Pune</h2>
                                    </CardContent>
                                    <Divider light />
                                    <FacebookIcon style={{ color: '#4267B2', margin: '6px', }} fontSize="large" />
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        className={classes.ratingBox}
                                    >
                                        <Rating name={'rating'} value={5} size={'small'} readOnly />
                                        <Typography variant={'body2'} className={classes.rateValue}>
                                            4.9
                                </Typography>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        className={classes.ratingBox}
                                    >
                                        <a href="https://www.facebook.com/FindMyRoomPune/reviews/" target="_blank" style={{ textDecoration: "none", }}>
                                            <Typography className={classes.reviewText}>
                                                256 Reviews
                                </Typography></a> |
                                <a href="https://www.facebook.com/FindMyRoomPune/reviews/" target="_blank" style={{ textDecoration: "none" }}>
                                            <Typography className={classes.reviewText}>
                                                Write a review
                                </Typography></a>
                                    </Box>
                                    <Divider light variant="middle" style={{ maxWidth: '70%', margin: '12px auto' }} />
                                    <Box display={'flex'}>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <p className={classes.statLabel}>Likes</p>
                                            <p className={classes.statValue}>32,871</p>
                                        </Box>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <p className={classes.statLabel}>Followers</p>
                                            <p className={classes.statValue}>10.1k</p>
                                        </Box>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <Chip
                                                label="Like Page"
                                                clickable
                                                icon={<ThumbUpAltIcon style={{ color: 'white' }} />}
                                                color="secondary"
                                                onClick={() => window.open("https://www.facebook.com/FindMyRoomPune/", "_blank")}
                                                style={{ color: 'white' }}
                                            />
                                        </Box>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <Chip
                                                label="Join Group"
                                                clickable
                                                icon={<AddIcon style={{ color: 'white' }} />}
                                                onClick={() => window.open("https://www.facebook.com/groups/FindMyRoomPune/", "_blank")}
                                                color="secondary"
                                                style={{ color: 'white' }}
                                            />
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={10} md={5} lg={3}>
                                <Card className={classNames(classes.cityPaper1)}>
                                    <CardContent className={classes.cardHyd}>
                                        <Avatar className={classes.avatar} src={hyddp} />
                                        <h2 className={classes.heading}>Flat and Flatmates<br></br>Hyderabad</h2>
                                    </CardContent>
                                    <Divider light />
                                    <FacebookIcon style={{ color: '#4267B2', margin: '6px', }} fontSize="large" />
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        className={classes.ratingBox}
                                    >
                                        <Rating name={'rating'} value={5} size={'small'} readOnly />
                                        <Typography variant={'body2'} className={classes.rateValue}>
                                            5.0
                                </Typography>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        className={classes.ratingBox}
                                    >
                                        <a href="https://www.facebook.com/FindMyRoomHyderabad/reviews/" target="_blank" style={{ textDecoration: "none", }}>
                                            <Typography className={classes.reviewText}>
                                                61 Reviews
                                </Typography></a> |
                                <a href="https://www.facebook.com/FindMyRoomHyderabad/reviews/" target="_blank" style={{ textDecoration: "none" }}>
                                            <Typography className={classes.reviewText}>
                                                Write a review
                                </Typography></a>
                                    </Box>
                                    <Divider light variant="middle" style={{ maxWidth: '70%', margin: '12px auto' }} />
                                    <Box display={'flex'}>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <p className={classes.statLabel}>Likes</p>
                                            <p className={classes.statValue}>14,136</p>
                                        </Box>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <p className={classes.statLabel}>Followers</p>
                                            <p className={classes.statValue}>2.8k</p>
                                        </Box>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <Chip
                                                label="Like Page"
                                                clickable
                                                icon={<ThumbUpAltIcon style={{ color: 'white' }} />}
                                                color="secondary"
                                                onClick={() => window.open("https://www.facebook.com/FindMyRoomHyderabad/", "_blank")}
                                                style={{ color: 'white' }}
                                            />
                                        </Box>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <Chip
                                                label="Join Group"
                                                clickable
                                                icon={<AddIcon style={{ color: 'white' }} />}
                                                onClick={() => window.open("https://www.facebook.com/groups/FindMyRoomHyderabad/", "_blank")}
                                                color="secondary"
                                                style={{ color: 'white' }}
                                            />
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={10} md={5} lg={3}>
                                <Card className={classNames(classes.cityPaper1)}>
                                    <CardContent className={classes.cardMumbai}>
                                        <Avatar className={classes.avatar} src={mumbaidp} />
                                        <h2 className={classes.heading}>Flat and Flatmates<br></br>Mumbai</h2>
                                    </CardContent>
                                    <Divider light />
                                    <FacebookIcon style={{ color: '#4267B2', margin: '6px', }} fontSize="large" />
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        className={classes.ratingBox}
                                    >
                                        <Rating name={'rating'} value={4.9} size={'small'} readOnly />
                                        <Typography variant={'body2'} className={classes.rateValue}>
                                            4.9
                                </Typography>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mb={1}
                                        className={classes.ratingBox}
                                    >
                                        <a href="https://www.facebook.com/FindMyRoomMumbai/reviews/" target="_blank" style={{ textDecoration: "none", }}>
                                            <Typography className={classes.reviewText}>
                                                103 Reviews
                                </Typography></a> |
                                <a href="https://www.facebook.com/FindMyRoomMumbai/reviews/" target="_blank" style={{ textDecoration: "none" }}>
                                            <Typography className={classes.reviewText}>
                                                Write a review
                                </Typography></a>
                                    </Box>
                                    <Divider light variant="middle" style={{ maxWidth: '70%', margin: '12px auto' }} />
                                    <Box display={'flex'}>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <p className={classes.statLabel}>Likes</p>
                                            <p className={classes.statValue}>11,882</p>
                                        </Box>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <p className={classes.statLabel}>Followers</p>
                                            <p className={classes.statValue}>406</p>
                                        </Box>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <Chip
                                                label="Like Page"
                                                clickable
                                                icon={<ThumbUpAltIcon style={{ color: 'white' }} />}
                                                color="secondary"
                                                onClick={() => window.open("https://www.facebook.com/FindMyRoomMumbai/", "_blank")}
                                                style={{ color: 'white' }}
                                            />
                                        </Box>
                                        <Box p={1} flex={'auto'} style={{ textAlign: 'center' }}>
                                            <Chip
                                                label="Join Group"
                                                clickable
                                                icon={<AddIcon style={{ color: 'white' }} />}
                                                onClick={() => window.open("https://www.facebook.com/groups/FindMyRoomMumbai/", "_blank")}
                                                color="secondary"
                                                style={{ color: 'white' }}
                                            />
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>

                        </Grid>
                    </section>
 */}

          {/* <section className={classes.section3}>
            <h2 className={classes.question}>Are you looking out for people to occupy a Room or an Apartment?</h2>
            <Link href="/post/rent">
                <Button variant="contained" color="secondary" className={classes.paperButton}>
                    Post a Listing Now!
                </Button>
            </Link>
            </section> */}
          {/*                        <section className={classes.section8}>
                       <h1 className={classes.sectionHeading4}>Select An Option To Continue..<br/></h1>
                <div className={classes.navCards}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={6} className={classes.paper1}>
                <Link href="/post/rent">
                    <Paper className={classes.postPagePaper}>
                       <HomeWorkIcon className="postPageIcons" color="secondary"/>
                      <img src={post_img} alt={"post_img"} width={"250px"} height={"150px"}/>
                      <h3 className={classes.lineHeight}>Got a room/house to rent?</h3><h4>List Roommate Requirement, Single Room, Entire House for Rent, PG's, Co-Living Spaces with us today</h4>
                        <Button variant="contained" color="secondary" style={{color: 'white', fontWeight: '900'}}>
                          POST FREE RENTAL AD
                        </Button>
                    </Paper>
                  </Link>
                </Grid>
                <Grid item xs={12} md={6} className={classes.paper1}>
                <Link href="/post/requirement">
                    <Paper className={classes.postPagePaper}>
                       <PostAddOutlinedIcon className="postPageIcons" color="secondary"/>
                      <img src={search_img} alt={"search_img"} width={"250px"} height={"150px"}/>
                      <h3 className={classes.lineHeight}>Need a room or House?</h3><h4>Post Requirement for Room, House or PG to Get Connected to Owners or Flatmates</h4>
                        <Button variant="contained" color="secondary" style={{color: 'white', fontWeight: '900'}}>
                        TELL US ABOUT YOUR REQUIREMENT
                        </Button>
                    </Paper>
                </Link>
                </Grid>
            </Grid>
                </div>
            </section> */}
        </div>
      </Layout>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HomePage);
